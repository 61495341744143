<template>
	<gc-dialog
		title="日志记录"
		width="960px"
		:show.sync="visible"
		:showCancelBtn="false"
		:showFooter="false"
		@close="$emit('close-modal')"
	>
		<div style="height: 600px">
			<gc-table
				border
				v-loading="loading"
				showPage
				:columns="columns"
				:table-data="tableData"
				:current-page.sync="pagination.currentPage"
				:total="pagination.total"
				:pageSize="pagination.pageSize"
				gloabSortDisable
				paginationLayout="prev, next, jumper"
				@current-page-change="currentPageChange"
			></gc-table>
		</div>
	</gc-dialog>
</template>

<script>
import { apiGetDeviceLogPageList } from '@/apis/firmware.api'
export default {
	name: 'Log',
	props: {
		deviceNo: String,
		subTaskId: String,
	},
	data() {
		return {
			visible: true,
			columns: [
				{
					key: 'createTime',
					name: '时间',
				},
				{
					key: 'operationStep',
					name: '日志动作',
				},
				{
					key: 'logSource',
					name: '日志来源',
				},
				{
					key: 'logDescribe',
					name: '日志记录',
				},
				{
					key: 'originalMessage',
					name: '原始报文',
				},
			],
			loading: false,
			pagination: {
				currentPage: 1,
				pageSize: 10,
				total: 0,
			},
			tableData: [],
		}
	},
	created() {
		this.findTableList()
	},

	methods: {
		async findTableList() {
			this.loading = true
			const searchParams = {
				deviceNo: this.deviceNo,
				subTaskId: this.subTaskId,
				current: this.pagination.currentPage,
				size: this.pagination.pageSize,
			}
			try {
				const { records = [], total } = await apiGetDeviceLogPageList(searchParams)
				this.tableData = records
				this.pagination.total = total
			} finally {
				this.loading = false
			}
		},

		currentPageChange(current) {
			this.pagination.currentPage = current
			this.findTableList()
		},
	},
}
</script>
<style lang="scss" scoped></style>
