<template>
	<div class="page">
		<gc-header headerData="firamwareDeviceDetail"></gc-header>
		<div class="page-main directive-detail-main">
			<gc-group-detail :data="deviceAttributeInfo"></gc-group-detail>
			<div class="directive-detail-table-show">
				<div class="table-header">升级记录</div>
				<div class="table-box">
					<gc-table
						border
						v-loading="loading"
						showPage
						:columns="columns"
						:table-data="tableData"
						:current-page.sync="pagination.currentPage"
						:total="pagination.total"
						:pageSize="pagination.pageSize"
						gloabSortDisable
						paginationLayout="prev, next, jumper"
						@current-page-change="currentPageChange"
					></gc-table>
				</div>
			</div>
		</div>
		<Log v-if="logVisible" :subTaskId="subTaskId" :deviceNo="deviceNo" @close-modal="logVisible = false" />
	</div>
</template>

<script>
import {
	subTaskStatusOptions,
	vertifyStatusOptions,
	downloadStatusOptions,
	firmwareTypeMap,
	downloadStatusMap,
	vertifyStatusMap,
	subTaskStatusMap,
} from '@/config/const'
import { apiGetDeviceDetails, apiGetDeviceTaskDetails } from '@/apis/firmware.api'
import Log from './components/Log'

export default {
	name: 'deviceDetail',
	components: { Log },
	data() {
		return {
			logVisible: false,
			subTaskStatusOptions,
			vertifyStatusOptions,
			downloadStatusOptions,
			deviceNo: this.$route.query.deviceNo,
			deviceDetails: {},
			columns: [
				{
					key: 'taskName',
					name: '批次任务名称',
					width: 130,
					tooltip: true,
				},
				{
					key: 'firmwareType',
					name: '固件类型',
					render: (h, row) => {
						return h('span', null, firmwareTypeMap[row.firmwareType])
					},
				},
				{
					key: 'preVersion',
					name: '前置版本号',
				},
				{
					key: 'targetVersion',
					name: '目标版本号',
				},
				{
					key: 'downloadStatus',
					name: '固件下载状态',
					render: (h, row) => {
						return h('span', null, downloadStatusMap[row.downloadStatus])
					},
				},
				{
					key: 'verifyStatus',
					name: '验证状态',
					render: (h, row) => {
						return h('span', null, vertifyStatusMap[row.verifyStatus])
					},
				},
				{
					key: 'taskStatus',
					name: '任务状态',
					render: (h, row) => {
						return h('span', null, subTaskStatusMap[row.taskStatus])
					},
				},
				{
					key: 'execDownloadCount',
					name: '下载次数',
				},
				{
					key: 'createStaffName',
					name: '创建人',
				},
				{
					key: 'createTime',
					name: '创建时间',
					width: 180,
				},
				{
					key: 'oprate',
					name: '操作',
					sortDisable: true,
					width: 130,
					fixed: 'right',
					render: (h, row) => {
						return h('div', {}, [
							h(
								'el-button',
								{
									props: {
										type: 'text',
										size: 'medium',
									},
									on: {
										click: () => {
											this.subTaskId = row.otaTaskDetailId
											this.logVisible = true
										},
									},
								},
								'日志记录',
							),
						])
					},
				},
			],
			loading: false,
			pagination: {
				currentPage: 1,
				pageSize: 10,
				total: 0,
			},
			tableData: [],
			subTaskId: '',
		}
	},
	computed: {
		deviceAttributeInfo() {
			const d = this.deviceDetails
			return {
				title: '设备属性',
				row: 4,
				list: [
					{
						key: 'deviceNo',
						label: '设备编号',
						value: d.deviceNo,
					},
					{
						key: 'otaDeviceTypeName',
						label: 'OTA设备类型',
						value: d.otaDeviceTypeName,
					},
					{
						key: 'mainControlVersion',
						label: '主控板版本号',
						value: d.mainControlVersion,
					},
					{
						key: 'communicationVersion',
						label: '通讯板版本号',
						value: d.communicationVersion,
					},
					{
						key: 'lastCommunicateTime',
						label: '最后通讯时间',
						value: d.lastCommunicateTime,
					},
					{
						key: 'createStaffName',
						label: '创建人',
						value: d.createStaffName,
					},
					{
						key: 'createTime',
						label: '创建时间',
						value: d.createTime,
					},
				],
			}
		},
	},

	created() {
		this.queryDeviceDetails()
		this.findTableList()
	},

	methods: {
		/**
		 * 查询设备详情
		 * @param {*} deviceNo
		 */
		async queryDeviceDetails() {
			const data = await apiGetDeviceDetails(this.deviceNo)
			this.deviceDetails = data
		},

		async findTableList() {
			this.loading = true
			const searchParams = {
				deviceNo: this.deviceNo,
				current: this.pagination.currentPage,
				size: this.pagination.pageSize,
			}
			try {
				const { records = [], total } = await apiGetDeviceTaskDetails(searchParams)
				this.tableData = records
				this.pagination.total = total
			} finally {
				this.loading = false
			}
		},

		currentPageChange(current) {
			this.pagination.currentPage = current
			this.findTableList()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../../run-manage/runLayout.scss';
.directive-detail-main {
	flex-direction: column;
	padding: 0 20px;
	.directive-detail-table-show {
		flex: 1;
		display: flex;
		flex-direction: column;
		height: 0;
		.table-header {
			font-size: 17px;
			font-weight: 600;
			color: #333333;
			padding-bottom: 20px;
		}
		.table-box {
			flex: 1;
			height: 0;
			display: flex;
			flex-direction: column;
			.serach {
				display: flex;
				flex-wrap: wrap;
				.serach-ops {
					::v-deep .el-button {
						border-radius: 8px;
					}
				}
				& > div {
					margin-right: 12px;
					margin-bottom: 8px;
				}
				.export-ops {
					margin-left: auto;
					margin-right: 0;
				}
			}
			::v-deep .gc-table {
				flex: 1;
				height: 0;
				margin-top: 8px;
			}
		}
	}
}
::v-deep .desc {
	.el-textarea.is-disabled .el-textarea__inner {
		border: 1px solid #d9d9d9;
		background: #ffffff !important;
		color: #4e4e4e;
	}
}
</style>
